<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.escopo_servico.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.escopo_servico.titulos.novo')"
      :sem-botao-ok="!buscarPermissao('Escopo', 'Inserir')"
      sem-filtro
      @abrirNovo="abrirNovo"
    />
    <botao-padrao
      v-if="podeExcluir && buscarPermissao('Escopo', 'Deletar')"
      class="my-2"
      outlined
      color="danger"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      filtros-com-colunas
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      mostrar-acoes
      selecionar-colunas
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :filters="filters"
      :global-filters="globalFilters"
      :pagina="tabela.paginaAtual"
      :mostrar-seletor="buscarPermissao('Escopo', 'Deletar')"
      @paginar="listarRegistros"
      @filtrarGlobal="filtrarGlobal"
      @filtroGlobal="filtroGlobal"
      @nova-ordenacao="ordenacaoColuna"
    >
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao
          v-if="buscarPermissao('Escopo', 'Editar')"
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <dropdown-padrao-item @click="abrirEditar(slotProps.data)">
            {{ $t('geral.botoes.editar') }}
          </dropdown-padrao-item>
        </dropdown-padrao>
      </template>
      <template v-slot:faixa="{ slotProps }">
        <coluna-multiplos-itens
          v-if="slotProps.data?.faixa"
          custom-style="max-width: 1000px;"
          :item="montarViewMultiplosItens(slotProps.data?.faixas)"
        />
      </template>
      <template v-slot:localExecucao="{ slotProps }">
        <coluna-multiplos-itens
          v-if="slotProps.data?.localExecucao"
          custom-style="max-width: 1000px;"
          :item="montarViewMultiplosItens(slotProps.data?.locaisExecucoes)"
        />
      </template>
      <template v-slot:tipoCalibracao="{ slotProps }">
        <coluna-multiplos-itens
          v-if="slotProps.data?.tipoCalibracao"
          custom-style="max-width: 1000px;"
          :item="montarViewMultiplosItens(slotProps.data?.tiposCalibracoes)"
        />
      </template>
    </tabela-padrao-prime-vue>
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import EscopoServicoService from '@common/services/cadastros/EscopoServicoService.js';
import helpers from '../../../../common/utils/helpers';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import ColunaMultiplosItens from '@/components/tabelas/ColunaMultiplosItens.vue';
export default {
  components: {
    CabecalhoPagina,
    ColunaMultiplosItens
  },

  data() {
    return {
      globalFilters: [
        'codigo',
        'acreditacao.codigo',
        'nome',
        'locaisExecucoes',
        'tiposCalibracoes',
        'faixas',
      ],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        codigo: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        acreditacao: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        nome: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        locaisExecucoes: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        tiposCalibracoes: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        faixas: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'codigo',
            text: this.$t('modulos.escopo_servico.tabela.codigo'),
            sortable: true,
          },
          {
            value: 'acreditacao.codigo',
            text: this.$t('modulos.escopo_servico.tabela.acreditacao'),
            sortable: true,
          },
          {
            value: 'nome',
            text: this.$t('modulos.escopo_servico.tabela.nome'),
            sortable: true,
          },
          {
            value: 'localExecucao',
            text: this.$t('modulos.escopo_servico.tabela.locaisExecucao'),
          },
          {
            value: 'tipoCalibracao',
            text: this.$t('modulos.escopo_servico.tabela.qtdTipoCalibracao'),
          },
          {
            value: 'faixa',
            text: this.$t('modulos.escopo_servico.tabela.qtdFaixas'),
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      colunaOrdenada: '',
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'Escopo', 'Listar');
    const filtros = this.buscarStateFiltros();
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
  },
  mounted() {
    this.listarRegistros();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.escopo_servico.titulos.listagem')
    );
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros: function (
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      this.tabela.porPagina = porPagina;
      this.salvarStateFiltros({
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
      });
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        sort: this.colunaOrdenada,
        ...this.filtroAplicado,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      EscopoServicoService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros: async function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        let promises = [];
        this.tabela.selecionados.forEach((registro) => {
          promises.push(EscopoServicoService.excluir(registro.id));
        });

        Promise.all(promises)
          .then(() => {
            this.listarRegistros();
            this.toastSucesso(
              this.$t(`modulos.escopo_servico.exclusao_sucesso`)
            );
            this.tabela.selecionados = [];
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    abrirNovo: function () {
      this.$router.push({ name: 'escopo-servico-novo' });
    },
    abrirEditar: function (item) {
      this.$router.push({
        name: 'escopo-servico-editar',
        params: { id: item.id },
      });
    },
    buscarStateFiltros() {
      return this.$store.getters['Filtros/filtros'][this.$route.name];
    },
    salvarStateFiltros(dados) {
      this.$store.dispatch('Filtros/adicionarFiltro', {
        rota: this.$route.name,
        dados,
      });
    },
    filtroGlobal(v) {
      this.filtroAplicado.filter = v;
    },
    filtrarGlobal() {
      this.listarRegistros();
    },
    montarViewMultiplosItens(itens) {
      return {
        value: itens?.map((v) => {
          return { nome: v.nome };
        }),
      };
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros()
    },
  },
};
</script>
<style lang="scss">
.alinhar-direita {
  display: flex;
  justify-content: right;
}
</style>
